
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsw0xrDLQPq4Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93bjZMWZhEhBMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor1jLTVWLzUXMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/blog/editor.vue?macro=true";
import { default as indexcvnWOTmtmnMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/index.vue?macro=true";
import { default as moderationTMeDTPoKzRMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/moderation.vue?macro=true";
import { default as pendingBansNv2rDD0PPRMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/pendingBans.vue?macro=true";
import { default as profilesBOFa77MDUNMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/profiles.vue?macro=true";
import { default as expensesssJHNV7nq5Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexwbDAkzBz8yMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/index.vue?macro=true";
import { default as overview02WXpgY8BeMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingBxgMMUrIo1Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingCjDbZrpLxMMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/translations/missing.vue?macro=true";
import { default as users3PkDikZYzgMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/admin/users.vue?macro=true";
import { default as apiY4n1IOoRvgMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/api.vue?macro=true";
import { default as _91slug_93QqY2kTpJ13Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/blog/[slug].vue?macro=true";
import { default as indexyhNQ9BYYWLMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93DpScRWMssVMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_937e8oPpdUMgMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admingEboHhKf3QMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/census/admin.vue?macro=true";
import { default as indexqvQJVIjOrCMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/census/index.vue?macro=true";
import { default as contactIJPcwrr47nMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/contact.vue?macro=true";
import { default as designN6soiTvLkGMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/design.vue?macro=true";
import { default as englisheF8jiTvNdwMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/english.vue?macro=true";
import { default as faqE1FXUm84dbMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/faq.vue?macro=true";
import { default as inclusive09romJdAz3Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/inclusive.vue?macro=true";
import { default as index_45homeD7qwQawl15Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/index-home.vue?macro=true";
import { default as index_45localeoJZIiW8dDTMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/index-locale.vue?macro=true";
import { default as langswitcho7pQdVa3IYMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/langswitch.vue?macro=true";
import { default as licenseKKyJEZLDZVMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/license.vue?macro=true";
import { default as academicEt8zXyFoDpMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/links/academic.vue?macro=true";
import { default as indexMWtfF13qqQMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/links/index.vue?macro=true";
import { default as media79CtVPVxAAMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/links/media.vue?macro=true";
import { default as translinguistics5zNPM2wgCPMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/links/translinguistics.vue?macro=true";
import { default as zineFwoKItgeeOMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/links/zine.vue?macro=true";
import { default as namesQTdbvRVUFSMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/names.vue?macro=true";
import { default as indexceZUO0YltEMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/nouns/index.vue?macro=true";
import { default as templates3bnyo0UwB2Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/nouns/templates.vue?macro=true";
import { default as people3EtFURKP6yMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/people.vue?macro=true";
import { default as privacyjw7mHbLhX2Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/privacy.vue?macro=true";
import { default as _91username_93y45h2u7qKzMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Ivg5lIT993Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/profile/card-[username].vue?macro=true";
import { default as editorOM5iKqAbvAMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/profile/editor.vue?macro=true";
import { default as anysfNHL10WJIMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/any.vue?macro=true";
import { default as askSlbuuy7dPuMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/ask.vue?macro=true";
import { default as avoidingvnXqrn2mh1Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/avoiding.vue?macro=true";
import { default as indexcTqELGC2K3Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/index.vue?macro=true";
import { default as mirror7eSx5Mt5w9Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/mirror.vue?macro=true";
import { default as pronounnzjjmZco42Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesLkuJnQ7UTpMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/sources.vue?macro=true";
import { default as teamCeBmQdI0raMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/team.vue?macro=true";
import { default as terminologyXgttRIIwyBMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/terminology.vue?macro=true";
import { default as termsXAz1dSCLX0Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/terms.vue?macro=true";
import { default as userWGvZEjN9f0Meta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/user.vue?macro=true";
import { default as workshopslrGXS5dpRtMeta } from "/home/admin/www/pronombr.es/release/20250119181057/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsw0xrDLQPq4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansNv2rDD0PPRMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiY4n1IOoRvgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93QqY2kTpJ13Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexyhNQ9BYYWLMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93DpScRWMssVMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_937e8oPpdUMgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admingEboHhKf3QMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexqvQJVIjOrCMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactIJPcwrr47nMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englisheF8jiTvNdwMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqE1FXUm84dbMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive09romJdAz3Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeD7qwQawl15Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localeoJZIiW8dDTMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitcho7pQdVa3IYMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicEt8zXyFoDpMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexMWtfF13qqQMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media79CtVPVxAAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics5zNPM2wgCPMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineFwoKItgeeOMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesQTdbvRVUFSMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexceZUO0YltEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates3bnyo0UwB2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people3EtFURKP6yMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyjw7mHbLhX2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93y45h2u7qKzMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Ivg5lIT993Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorOM5iKqAbvAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anysfNHL10WJIMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askSlbuuy7dPuMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingvnXqrn2mh1Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexcTqELGC2K3Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror7eSx5Mt5w9Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounnzjjmZco42Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesLkuJnQ7UTpMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamCeBmQdI0raMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyXgttRIIwyBMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsXAz1dSCLX0Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userWGvZEjN9f0Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopslrGXS5dpRtMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20250119181057/pages/workshops.vue")
  }
]