import routerOptions0 from "/home/admin/www/pronombr.es/release/20250119181057/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_esggzfy5fxpj3hrvklsx4qwgpu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/admin/www/pronombr.es/release/20250119181057/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}